import React, { useState } from "react";
import { Link } from "react-router-dom";
import blogData from "./data";
import "./Blog.css";
import SEO from "../../components/SEO";
import HeroComponent from "../../components/Hero";
import TelegramQR from "../../../src/assests/images/telegram_QR.png";
import WhatsAppQR from "../../../src/assests/images/WhatsApp_QR.png";
import { submitContactForm } from "../../services";
import CustomToast from "../../components/Toast";
import { Height } from "@material-ui/icons";

const BlogCard = ({ id, title, image, desc, date, author, category, slug }) => {
  const truncatedDescription =
    desc.length > 150 ? `${desc.slice(0, 150)}...` : desc;
  return (
    <Link to={`/blog-detail/${id}/${slug}`} className="blog-card-link" style={{ textDecoration: "none" }}  target="_blank"
    rel="noopener noreferrer">
      <div className="blog-card">
        <img src={image} alt={title} className="blog-card-image" />
        <h4 style={{ color: "black", fontWeight:"600" }}>{title}</h4>
        <p style={{color:"black", fontWeight:"400", borderTop:"1px solid black", paddingTop:"8px", borderBottom:"1px solid black", paddingBottom:"8px"}}>{date}</p>
        <p dangerouslySetInnerHTML={{ __html: truncatedDescription }} style={{ color: "black", fontWeight:400 }} />
      </div>
    </Link>
  );
};

const Sidebar = ({ searchText, setSearchText, handleSearch }) => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });
 
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleShowToast = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const handleHideToast = () => {
    setShowToast(false);
  };

  // Handle form submission
  const handleFormSubmit = async () => {

    const payload = {
      fields: [
        { name: "email", value: formData.email },
        { name: "name", value: formData.name },
        { name: "phone", value: formData.phone },
        { name: "projectdescription", value: formData.projectdescription },
      ],
    };
    try {
      const res = await submitContactForm(payload);
      if (res?.inlineMessage === "Thanks for submitting the form.") {
        setFormData({
          name: "",
          email: "",
          phone: "",
          projectdescription: "",
        });
        handleShowToast();
        setToastMessage("We'll get back to you soon!");
      } else {
        handleShowToast();
        setToastMessage("Some error occurred. Please try again!");
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      handleShowToast();
      setToastMessage("Failed to submit. Please try again later.");
    }
  };

  return (
   <div className="sidebar">
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <input
      type="text"
      placeholder="Search..."
      className="search-bar"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
    />
    <button
      style={{
        backgroundColor: "#f44336",
        color: "white",
        padding: "8px 12px",
        border: "none",
        cursor: "pointer",
        borderRadius: "4px",
      }}
      onClick={handleSearch}
    >
      Search
    </button>
  </div>
  <div className="banner"></div>
  <div className="qr-code">
    <div style={{ display: "flex", flexDirection: "row" }}>
      <img src={WhatsAppQR} style={{ width: "50%" }} alt="WhatsApp QR" />
      <img src={TelegramQR} style={{ width: "50%" }} alt="Telegram QR" />
    </div>
    <p style={{fontWeight:"600"}}>Scan QR Code for Immediate Contact</p>
  </div>

  {/* Ensure the sidebar has enough height to scroll */}
  <div className="contact-form-container" >
    <div
      className="contact-form"
      // style={{
      //   position: "sticky",
      //   top: "100px", // Adjust top based on your design
      //   zIndex: "100",
      //   backgroundColor: "#fff",
      //   padding: "10px",
      //   border: "1px solid #ccc",
      // }}
    >
      <h3>Contact Us</h3>
      <input
        type="text"
        placeholder="Your Name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />
      <input
        type="email"
        placeholder="Your Email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
      />
      <input
        type="phone"
        placeholder="Your Mobile Number"
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
      />
      <textarea
        placeholder="Your Message"
        name="projectdescription"
        value={formData.projectdescription}
        onChange={handleInputChange}
      ></textarea>
      <button
        style={{ backgroundColor: "#f44336" }}
        onClick={handleFormSubmit}
      >
        Submit
      </button>
    </div>
  </div>
</div>
  );
};

const BlogList = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState(blogData);

  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  // Handle search
  const handleSearch = () => {
    if (searchText.trim() === "") {
      alert("Please enter text to search.");
      return;
    }
    const matchedBlogs = blogData.filter((blog) =>
      blog.title.toLowerCase().includes(searchText.toLowerCase()) ||
      blog.cateogry.toLowerCase().includes(searchText.toLocaleLowerCase())
    );
    setFilteredBlogs(matchedBlogs.reverse());
    setCurrentPage(1); // Reset to first page after search
  };

  // Get current blogs to display
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const firstBlog = currentBlogs.slice(0, 1); // Display first blog prominently
  const otherBlogs = currentBlogs.slice(1); // Remaining blogs for grid display

  return (
    <div className="blog-list">
      <SEO
        title="Digital Crew Blog | Insights on Technology, Marketing, and Innovation"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/blog"
      />

      <div className="blog-layout">
        <div className="main-content">
          {/* Display the first blog prominently */}
          {currentBlogs.length > 0 ? (
            <>
              <div className="featured-blog">
                {firstBlog.map((blog) => (
                  <BlogCard
                    key={blog.id}
                    id={blog.id}
                    title={blog.title}
                    image={blog.image}
                    desc={blog.description}
                    date={blog.date}
                    slug={blog.slug}
                    category={blog.cateogry}
                    author={blog.author}
                  />
                ))}
              </div>

              {/* Display remaining blogs in a grid layout */}
              <div className="card-grid">
                {otherBlogs.map((blog) => (
                  <BlogCard
                    key={blog.id}
                    id={blog.id}
                    title={blog.title}
                    image={blog.image}
                    desc={blog.description}
                    date={blog.date}
                    slug={blog.slug}
                    category={blog.cateogry}
                    author={blog.author}
                  />
                ))}
              </div>

              <div className="pagination" style={{ marginTop: '20px', textAlign: 'center', display:"flex", justifyContent:"center", alignItems:"center"  }}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  padding: '10px 20px',
                  backgroundColor: currentPage === 1 ? '#d3d3d3' : '#f44336',
                  color: currentPage === 1 ? '#888' : 'white',
                  cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                  borderRadius: '4px',
                  border: 'none',
                  fontSize: '16px',
                  marginRight: '10px',
                }}
              >
                Previous
              </button>

              <span style={{ margin: '0 10px' }}>
                Page {currentPage} of {totalPages}
              </span>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{
              padding: '10px 20px',
              backgroundColor: currentPage === Math.ceil(filteredBlogs.length / blogsPerPage) ? '#d3d3d3' : '#f44336',
              color: currentPage === Math.ceil(filteredBlogs.length / blogsPerPage) ? '#888' : 'white',
              cursor: currentPage === Math.ceil(filteredBlogs.length / blogsPerPage) ? 'not-allowed' : 'pointer',
              borderRadius: '4px',
              border: 'none',
              fontSize: '16px',
                }}
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <p>No blogs matching with your search</p>
        )}
      </div>

        <Sidebar
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default BlogList;
